import React from "react";
import withLayout from "../hoc/Layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectedCategoryAction } from "../actions";

const Home = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div>
      <section className="home" id="home">
        <div className="content">
          <b>
            <h3>
              <span>Fresh </span>and <span>Live</span> products
            </h3>
          </b>
          <b>
            <p>At your door step</p>
          </b>
        </div>
      </section>
      <section className="features" id="features">
        <h1 className="heading">
          {" "}
          <span>FEATURES</span>{" "}
        </h1>

        <div className="box-container">
          <div className="box">
            <img src="image/feature-img-1.png" alt="" />
            <h3>fresh and live</h3>
            <p>
              Live and dry fish is avaliable which was collected from the local
              fishermen near to your location
            </p>
          </div>

          <div className="box">
            <img src="image/feature-img-2.png" alt="" />
            <h3>preorder intakes</h3>
            <p>
              we utmost your priority and started preorder intake before the
              desired day with full stock
            </p>
          </div>

          <div className="box">
            <img src="image/feature-img-3.png" alt="" />
            <h3>easy payments</h3>
            <p>
              cash on delivery and QR code scanning is also avaliable at the
              delivery agent or at the hubs provided
            </p>
          </div>
        </div>
      </section>
      <section className="categories" id="categories">
        <h1 className="heading">
          {" "}
          <span>CATEGORIES</span>{" "}
        </h1>

        <div className="box-container">
          <div className="box">
            <img src="image/cat-1.png" alt="" />
            <h3>sea fishes</h3>
            <p>upto 25% off on first order</p>
            <button
              onClick={() => {
                dispatch(selectedCategoryAction("seaFish"));
                navigate("./products");
              }}
              className="btn"
            >
              Shop now
            </button>
          </div>

          <div className="box">
            <img src="image/cat-2.png" alt="" />
            <h3>fresh water fishes</h3>
            <p>upto 10% off on first order</p>
            <button
              onClick={() => {
                dispatch(selectedCategoryAction("freshWater"));
                navigate("./products");
              }}
              className="btn"
            >
              Shop now
            </button>
          </div>

          <div className="box">
            <img src="image/cat-3.png" alt="" />
            <h3>dry fish products</h3>
            <p>upto 15% off on first order</p>
            <button
              onClick={() => {
                dispatch(selectedCategoryAction("dryFish"));
                navigate("./products");
              }}
              className="btn"
            >
              Shop now
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withLayout(Home);
