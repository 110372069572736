import React from "react";
import { useNavigate } from "react-router-dom";

const ConfirmOrder = (props) => {
  const navigate = useNavigate();
  return (
    <div className="confirm">
      <h1 className="lg-title">Order Confirmed</h1>
      <div className="container">
        <div className="confirm-details bg-white ck-form">
          <h2>
            Thankyou for shopping with us <span>{props.userName}</span>
          </h2>
          <h2>
            Your shipment with order Id <span>#{props.orderId}</span> will be shipped soon
            and should be delivered with in 7 to 10 business days
          </h2>
          <h2>
            Please keep the Amount <span>Rs.{props.cartTotal}/-</span> in hand on the day of
            delivery
          </h2>
          <div className="checkout-btn ck-forms">
            <button
              className="btn green-btn btn-confirm"
              onClick={() => navigate("/")}
            >
              Continue Shopping
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOrder;
