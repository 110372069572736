export const selectedCategoryAction = (category) => {
  return {
    type: "SELECTED_CATEGORY",
    payload: category,
  };
};

export const updateCartAction = (cartItems) => {
  return {
    type: "UPDATE_CART",
    payload: cartItems,
  };
};

export const updateCartTotal = (cartTotal) => {
  return {
    type: "UPDATE_CART_TOTAL",
    payload: cartTotal,
  };
};

export const addUser = (user) => {
  return {
    type: "ADD_USER",
    payload: user,
  };
};

export const updateUserId = (userId) => {
  return {
    type: "USER_ID",
    payload: userId,
  };
};
