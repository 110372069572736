import React from "react";

const Footer = () => {
  return (
    <section className="footer">
      <div className="box-container">
        <div className="box">
          <h3>
            {" "}
            <img
              src="https://github.com/Matsya-03161013/matsya/blob/main/title.logo.png?raw=true"
              width="40"
              height="40"
            />{" "}
            <b>MATSYA</b>
          </h3>
          <p>
            The online platform for marine
            <br /> products exports and imports
          </p>
          <div className="share">
            <a href="#" className="fab fa-facebook-f"></a>
            <a href="#" className="fab fa-twitter"></a>
            <a href="#" className="fab fa-instagram"></a>
          </div>
        </div>

        <div className="box">
          <h3>contact info</h3>
          <a className="links">
            {" "}
            <i className="fas fa-phone"></i>9949233606{" "}
          </a>
          <a className="links">
            {" "}
            <i className="fas fa-phone"></i> 8341296799{" "}
          </a>
          <a herf="mailto:matsya.03161013 @gmail.com" className="links">
            {" "}
            <i className="fas fa-envelope"></i>matsya.03161013 @gmail.com{" "}
          </a>
          <a className="links">
            {" "}
            <i className="fas fa-map-marker-alt"></i> rajahmundry,AP, india - 533101{" "}
          </a>
        </div>
      </div>

      <div className="credit">
        {" "}
        <b>
          DESIGNED AND DEVELOPED by <span> TEAM-MATSYA </span> | all rights
          reserved
        </b>{" "}
      </div>
    </section>
  );
};

export default Footer;