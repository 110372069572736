import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import withLayout from "../hoc/Layout";
import emailjs from "@emailjs/browser";
import ConfirmOrder from "./ConfirmOrder";

const Checkout = () => {
  const { cartItems, cartTotal, users, userId } = useSelector(
    (state) => state.root
  );
  const [userDetails, setUserDetails] = useState({});
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [inputObj, setInputObj] = useState({
    address1: "",
    address2: "",
    pincode: "",
    village: "",
    city: "",
    state: "",
  });

  const [errorObj, setErrorObj] = useState({
    address1: true,
    pincode: true,
    village: true,
    city: true,
    state: true,
  });

  useEffect(() => {
    if (userId) {
      const userData = users.find((user) => user.id === userId);
      setUserDetails(userData);
    }
  }, []);
  const productsCatMap = {
    seaFish: "Sea Fishs",
    freshWater: "Fresh Water Fishes",
    dryFish: "Dry Fish"
  };

  const confirmOrder = () => {
    setErrorObj({
      ...errorObj,
      address1: inputObj.address1.length ? true : false,
      pincode: inputObj.pincode.length ? true : false,
      village: inputObj.village.length ? true : false,
      city: inputObj.city.length ? true : false,
      state: inputObj.state.length ? true : false,
    });
    if (
      inputObj.address1 &&
      inputObj.city &&
      inputObj.pincode &&
      inputObj.state &&
      inputObj.village
    ) {
      if (!userId) {
        alert("Please login to confirm your order");
        return;
      }
      const templateParams = {
        to_name: `${userDetails.firstName} ${userDetails.lastName}`,
        order_id: Math.floor(Math.random() * 2356123),
        address1: inputObj.address1,
        address2: inputObj.address2,
        pincode: inputObj.pincode,
        village: inputObj.village,
        city: inputObj.city,
        state: inputObj.state,
        total: cartTotal,
      };
      emailjs
        .send(
          "service_ogozmpc",
          "template_3im7nf8",
          templateParams,
          "m_clO-fO5hmdS3Ckj"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setOrderConfirmed(true);
            setOrderId(templateParams.order_id);
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
  };
  console.log(inputObj, errorObj);
  return (
    <div className="checkout">
      {!orderConfirmed && (
        <div className="container">
          <h1 className="lg-title">Order Confirmation</h1>
          <div className="order-details">
            <div className="left-pane pane">
              {cartItems.map((item, i) => {
                return (
                  <>
                    <div className="cart-item">
                      <div className="item-image">
                        <img src={item.imageUrl} alt={`${item.name} Fish`} />
                      </div>
                      <div className="item-details">
                        <h3 className="md-title h3-title">{item.name}</h3>
                        <h4 className="h4-text gray-text">{`Category: ${
                          productsCatMap[item.category]
                        }`}</h4>
                        <div className="price-details">
                          <h4 className="h4-text">Qty: {item.qty}</h4>
                          <h4 className="h4-text">
                            ₹ {parseFloat(item.discountedPrice) * item.qty}
                          </h4>
                        </div>
                      </div>
                    </div>
                    {i !== cartItems.length - 1 && <hr />}
                  </>
                );
              })}
            </div>
            <div className="right-pane pane">
              <h3 className="md-title h3-title">Price Details</h3>
              <hr></hr>
              <div className="price-item">
                <h4 className="h4-text">Price:</h4>
                <h4 className="h4-text">₹ {cartTotal}</h4>
              </div>
              <div className="price-item">
                <h4 className="h4-text">Delivery charges:</h4>
                <h4 className="h4-text green-text">Free</h4>
              </div>
              <hr />
              <div className="price-item">
                <h2 className="h2-text gray-text">Total Amount: </h2>
                <h2>₹ {cartTotal}</h2>
              </div>
            </div>
          </div>

          <div className="address-form ck-forms bg-white">
            <h3 className="h3-text addr-title">Address Details</h3>
            <input
              type="text"
              placeholder="Address Line 1"
              className={`box address-box ${
                !errorObj.address1 ? "red-box" : ""
              }`}
              value={inputObj.address1}
              required
              onChange={(e) =>
                setInputObj({ ...inputObj, address1: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Address Line 2"
              className={`box address-box`}
              value={inputObj.address2}
              required
              onChange={(e) =>
                setInputObj({ ...inputObj, address2: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Village"
              className={`box address-box ${
                !errorObj.village ? "red-box" : ""
              }`}
              value={inputObj.village}
              required
              onChange={(e) =>
                setInputObj({ ...inputObj, village: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="City"
              className={`box address-box ${!errorObj.city ? "red-box" : ""}`}
              value={inputObj.city}
              required
              onChange={(e) =>
                setInputObj({ ...inputObj, city: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="State"
              className={`box address-box ${!errorObj.state ? "red-box" : ""}`}
              value={inputObj.state}
              required
              onChange={(e) =>
                setInputObj({ ...inputObj, state: e.target.value })
              }
            />
            <input
              type="number"
              placeholder="Pincode"
              className={`box address-box ${
                !errorObj.pincode ? "red-box" : ""
              }`}
              value={inputObj.pincode}
              required
              onChange={(e) =>
                e.target.value.length <= 6 &&
                setInputObj({ ...inputObj, pincode: e.target.value })
              }
            />
          </div>
          <div className="payment-details ck-forms bg-white">
            <h3 className="h3-text">Select Payment Method</h3>
            <div className="pay-methods">
              <input
                type="radio"
                name="payment-method"
                value="Cash On Delivery (COD)"
                checked
              />
              <label for="cod">Cash On Delivery (COD)</label>
            </div>
          </div>
          <div className="checkout-btn ck-forms">
            <button
              className="btn green-btn btn-confirm"
              onClick={confirmOrder}
            >
              Confirm Order
            </button>
          </div>
        </div>
      )}
      {orderConfirmed && (
        <ConfirmOrder
          userName={`${userDetails.firstName} ${userDetails.lastName}`}
          cartTotal={cartTotal}
          orderId={orderId}
        />
      )}
    </div>
  );
};

export default withLayout(Checkout);
