import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Checkout from "./components/Checkout";
import Home from "./components/Home";
import Products from "./components/Products";

function App() {
  const state = useSelector((state) => state);
  console.log(state);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/checkout" element={<Checkout />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
