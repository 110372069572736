import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addUser, updateUserId } from "../actions";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [selectedModal, setSelectedModal] = useState("");
  const { cartItems, users, userId } = useSelector((state) => state.root);
  const [inputObj, setInputObj] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [userDetails, setUserDetails] = useState({});
  const total = useSelector((state) => state.root.cartTotal);

  useEffect(() => {
    if (userId) {
      const userData = users.find(user => user.id === userId);
      setUserDetails(userData)
    }
  }, [userId]);

  const handleLogin = () => {
    const userData = users.find(
      (user) =>
        user.email === inputObj.email && user.password === inputObj.password
    );
    if (userData) {
      alert("Logged in succesfully");
      dispatch(updateUserId(userData.id));
    } else {
      alert("Incorrect Email or Password.");
    }
  };

  const handleSignup = () => {
    const userData = {
      ...inputObj,
      id: Date.now() + Math.floor(Math.random() * 2138938),
    };
    setTimeout(() => {
      dispatch(addUser(userData));
      alert("User registered succesfully. Please Login to continue");
      setSelectedModal("login");
    }, 1500);
  };
  return (
    <header className="header">
      <a href="#" className="logo">
        {" "}
        <img
          src="https://github.com/Matsya-03161013/matsya/blob/main/title.logo.png?raw=true"
          width="80"
          height="70"
        />
        <b> MATSYA </b>
      </a>

      <nav className="navbar">
        <b>
          <a href="./#home">home</a>
        </b>
        <b>
          <a href="./#features">features</a>
        </b>
        <b>
          <a href="./#categories">categories</a>
        </b>
        <b>
          <a href="./products">products</a>
        </b>
      </nav>

      <div className="icons">
        <div className="fas fa-bars" id="menu-btn"></div>
        <div className="fas fa-search" id="search-btn"></div>
        <div
          className="fas fa-shopping-cart"
          id="cart-btn"
          onClick={() =>
            setSelectedModal(selectedModal === "cart" ? "" : "cart")
          }
        ></div>
        <div
          className="fas fa-user"
          id="login-btn"
          onClick={() =>
            setSelectedModal(selectedModal === "login" ? "" : "login")
          }
        ></div>
      </div>

      <form action="#blogs" className="search-form">
        <input type="search" id="search-box" placeholder="search here..." />
        <label for="search-box" className="fas fa-search"></label>
      </form>
      <div
        className={`shopping-cart ${selectedModal === "cart" ? "active" : ""}`}
      >
        {cartItems.length ? (
          <>
            {cartItems.map((item) => {
              return (
                <div className="box">
                  <i className="fas fa-trash "></i>
                  <img src="image/cart-img-1.png" alt="" />
                  <div className="content">
                    <h3>{item.name}</h3>
                    <span className="price">{item.price}/-</span>
                    <span className="quantity">qty : {item.qty} </span>
                  </div>
                </div>
              );
            })}
            <div className="total"> total : {total}/- </div>
            <a href="#blogs" className="btn" onClick={() => navigate('/checkout')}>
              checkout
            </a>
          </>
        ) : (
          <div>Cart is empty</div>
        )}
      </div>

      {!userId && (
        <form
          action=""
          className={`login-form ${selectedModal === "login" ? "active" : ""}`}
        >
          <h3>login now</h3>
          <input
            type="email"
            placeholder="your email"
            className="box"
            onChange={(e) =>
              setInputObj({ ...inputObj, email: e.target.value })
            }
          />
          <input
            type="password"
            placeholder="your password"
            className="box"
            onChange={(e) =>
              setInputObj({ ...inputObj, password: e.target.value })
            }
          />
          {/* <p>
          forget your password <a href="#">click here</a>
        </p> */}
          <p>
            don't have an account{" "}
            <a href="#" onClick={() => setSelectedModal("signup")}>
              create now
            </a>
          </p>
          <button className="btn" onClick={handleLogin}>
            Login
          </button>
        </form>
      )}

      {!userId && (
        <form
          action=""
          className={`login-form ${selectedModal === "signup" ? "active" : ""}`}
        >
          <h3>signup now</h3>
          <input
            type="text"
            placeholder="your first name"
            className="box"
            onChange={(e) =>
              setInputObj({ ...inputObj, firstName: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="your last name"
            className="box"
            onChange={(e) =>
              setInputObj({ ...inputObj, lastName: e.target.value })
            }
          />
          <input
            type="email"
            placeholder="your email"
            className="box"
            onChange={(e) =>
              setInputObj({ ...inputObj, email: e.target.value })
            }
          />
          <input
            type="password"
            placeholder="your password"
            className="box"
            onChange={(e) =>
              setInputObj({ ...inputObj, password: e.target.value })
            }
          />
          <p>
            Already have an account{" "}
            <a href="#" onClick={() => setSelectedModal("login")}>
              Login now
            </a>
          </p>
          <button className="btn" onClick={handleSignup}>
            Signup
          </button>
        </form>
      )}
      {userId && (
        <div
          className={`shopping-cart ${
            selectedModal === "login" ? "active" : ""
          }`}
        >
          <div className="loggedin-user">
            <h3 className="h3-text">Welcome <span>{userDetails.firstName} {userDetails.lastName},</span> Happy shopping</h3>
            <button className="btn" onClick={() => dispatch(updateUserId(""))}>
              Logout
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
