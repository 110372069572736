import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateCartAction, updateCartTotal } from "../actions";
import withLayout from "../hoc/Layout";

const Products = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  console.log(state);
  const productsList = require("../constants/products.json");
  console.log(productsList);
  const [products, setProducts] = useState([]);

  const handleAddToCart = (item) => {
    let cartItems = state.root.cartItems;
    const crtItem = cartItems.find((pd) => pd.id === item.id);
    if (crtItem) {
      const finalItems = cartItems.map((fi) => {
        if (fi.id === crtItem.id) {
          return {
            ...fi,
            qty: fi.qty + 1,
          };
        } else return { ...fi };
      });
      let totalPrice = finalItems?.reduce(
        (total, num) => total + parseFloat(num.discountedPrice) * num.qty,
        0
      );
      console.log(totalPrice);
      dispatch(updateCartTotal(totalPrice));
      dispatch(updateCartAction(finalItems));
    } else {
      cartItems = [...cartItems, { ...item, qty: 1 }];
      let totalPrice = cartItems?.reduce(
        (total, num) => total + parseFloat(num.discountedPrice) * num.qty,
        0
      );
      dispatch(updateCartTotal(totalPrice));
      dispatch(updateCartAction(cartItems));
    }

    // dispatch(updateCartAction(cartItems));
  };

  const handleBuyNow = (item) => {
    handleAddToCart(item);
    navigate("/checkout");
  };
  const productsCatMap = {
    seaFish: "Sea Fishs",
    freshWater: "Fresh Water Fishes",
    dryFish: "Dry Fish"
  };
  useEffect(() => {
    if (state.root.selectedCategory) {
      let filteredProducts = productsList.filter(
        (item) => item.category === state.root.selectedCategory
      );
      setProducts(filteredProducts);
    } else {
      setProducts(productsList);
    }
  }, [state.root.selectedCategory]);
  return (
    <div className="products">
      <div className="container">
        <h1 className="lg-title">
          <b>
            {state.root.selectedCategory
              ? productsCatMap[state.root.selectedCategory]
              : "All Products"}
          </b>
        </h1>
        <p className="text-light"></p>

        <div className="product-items">
          {products.map((item) => {
            return (
              <div className="product">
                <div className="product-content">
                  <div className="product-img">
                    <img
                      src={item.imageUrl}
                      width="300"
                      height="200"
                      alt="product image"
                    />
                  </div>
                  <div className="product-btns">
                    <button
                      type="button"
                      className="btn-cart"
                      onClick={() => handleAddToCart(item)}
                    >
                      {" "}
                      add to cart
                      <span>
                        <i className="fas fa-plus"></i>
                      </span>
                    </button>
                    <button
                      type="button"
                      className="btn-buy"
                      onClick={() => handleBuyNow(item)}
                    >
                      {" "}
                      buy now
                      <span>
                        <i className="fas fa-shopping-cart"></i>
                      </span>
                    </button>
                  </div>
                </div>

                <div className="product-info">
                  <div className="product-info-top">
                    {/* <!--<h2 className = "sm-title"></h2>
                                <div className = "rating">
                                    <span><i className = "fas fa-star"></i></span>
                                    <span><i className = "fas fa-star"></i></span>
                                    <span><i className = "fas fa-star"></i></span>
                                    <span><i className = "fas fa-star"></i></span>
                                    <span><i className = "far fa-star"></i></span>
                                </div> ---> */}
                  </div>
                  <a href="#" className="product-name">
                    {item.name}
                  </a>
                  <p className="product-price">Rs {item.price}/-</p>
                  <p className="product-price">RS {item.discountedPrice}/-</p>
                </div>

                <div className="off-info">
                  <h2 className="sm-title">{item.discountPercent} off</h2>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withLayout(Products);
