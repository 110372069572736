const initialState = {
  selectedCategory: "",
  cartItems: [],
  cartTotal: 0,
  users: [],
  userId: "",
};

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SELECTED_CATEGORY":
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case "UPDATE_CART":
      return {
        ...state,
        cartItems: action.payload,
      };

    case "UPDATE_CART_TOTAL":
      return {
        ...state,
        cartTotal: action.payload,
      };
    case "ADD_USER":
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case "USER_ID":
      return {
        ...state,
        userId: action.payload,
      };
    default:
      return state;
  }
};
