import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const withLayout = (PageComponent) => {
  return function WithPage({ ...props }) {
    return (
      <div>
        {/* Header component */}
        <Header />

        <PageComponent />

        <Footer />

    </div>
    );
  };
};

export default withLayout;